import React from 'react'
import cn from 'classnames'
import styles from './bookingcontroll.module.css';
import arrow from '../../assets/arrow.png'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import { Button, CheckBox, TextField } from "../../formfields";
import InputMask from 'react-input-mask';
import {
  getAuthAmount,
  createTransfer,
  processPayment,
} from '../../modules/requests/actions';

import {
  setNeedReceipt,
  setReceiptCompany,
  setReceiptDetails,
  setReceiptFirstName,
  setReceiptLastName,
  setOnlinePayment,
  setCardPAN,
  setCardExpDate,
  setCardCVV2,
} from '../../modules/info/actions';

const content = [
  {
    title: 'paymant.pay',
    text: `paymant.payText`
  },
  {
    title: 'paymant.ride',
    text: `paymant.rideText`
  },
];

const cards = [
  /^3[47][0-9]{13}$/,
  /^(6541|6556)[0-9]{12}$/,
  /^389[0-9]{11}$/,
  /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
  /^63[7-9][0-9]{13}$/,
  /^(?:2131|1800|35\d{3})\d{11}$/,
  /^9[0-9]{15}$/,
  /^(6304|6706|6709|6771)[0-9]{12,15}$/,
  /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/,
  /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
  /^(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}$/,
  /^(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}$/,
  /^(62[0-9]{14,17})$/,
  /^4[0-9]{12}(?:[0-9]{3})?$/,
  /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/,

];

export const PaymantComponent = ({
  step,
  setStep,

  orderId,
  needReceipt,
  receiptCompany,
  receiptDetails,
  receiptFirstName,
  receiptLastName,
  onlinePayment,
  cardPAN,
  cardExpDate,
  cardCVV2,
  orderAmount,
  orderCurrency,
  authAmount,
  authCurrency,

  setNeedReceipt,
  setReceiptCompany,
  setReceiptDetails,
  setReceiptFirstName,
  setReceiptLastName,
  setOnlinePayment,
  setCardPAN,
  setCardExpDate,
  setCardCVV2,
  getAuthAmount,
  createTransfer,
  processPayment,

}) => {
  const { t } = useTranslation();

  const [activePayTabIndex, setActivePayTabIndex] = React.useState(null);
  const handleChangePay = (index) => {
    if (index === 0) {
      window.ym('reachGoal','payonline');
    } else if (index === 1) {
      window.ym('reachGoal','payafter');
    }

    setActivePayTabIndex(index);
    switch (index) {
       case 0:  setOnlinePayment(true);  break;
       case 1:  setOnlinePayment(false);  break;
       default:  break;
    }
  };

  React.useEffect(() => {
    if (onlinePayment) {
      getAuthAmount();
    }
  }, [onlinePayment]);

  const [cardholder, setCardHolder] = React.useState('');
  const isValidCard = cardPAN && cards.some(item => item.test(+cardPAN.replace(/\s+/g,'').trim()));
  const isNext = {
    true:  cardPAN && cardExpDate && cardCVV2 && isValidCard && authAmount,
    false: true,
  };

  const [makePayment, setMakePayment] = React.useState(false);
  const createOrder = () => {
    if (!orderId)
      createTransfer(false);
    setMakePayment(true);
  };

  React.useEffect(() => {
    if (makePayment && orderId)
    {
      if (onlinePayment)
      {
         processPayment();
         setStep(3);
      } else {
         setStep(4);
      }
    }
  }, [makePayment, orderId, onlinePayment]);

  return (
    <div>
      <h1 className={styles.title}>
        <span>{t("personalInfo.paymentSectionTitle")}</span>
        <img alt="/" src={arrow} />
        {t("paymant.title")}
      </h1>
      <div className={styles.blockPayment}>
        {
          content.map((item, index) => (
            <div className={cn(styles.paymant, {[styles.paymantActive]: (index === activePayTabIndex)})} key={index} onClick={() => handleChangePay(index)}>
              <span className={styles.paymantTitle}>{t(item.title)}</span>
              <span className={styles.paymantText}>{t(item.text)}</span>
            </div>
          ))
        }
      </div>
      {
        onlinePayment && (
          <div className={styles.cardBlock}>
            <InputMask mask="9999 9999 9999 9999" value={cardPAN} onChange={(e) => setCardPAN(e.target.value)} maskPlaceholder={null}>
              {(inputProps) => (
                <div className={cn(styles.phone, styles.inputForm, {[styles.formError]: !isValidCard && cardPAN})}>
                  <TextField
                    label={t("paymant.card")}
                    {...inputProps}
                    required
                    type="tel"
                  />
                </div>
              )}
            </InputMask>
            <div className={cn(styles.phone, styles.inputForm)}>
              <TextField
                label={t("paymant.cardholder")}
                value={cardholder}
                onChange={(e) => setCardHolder(e.target.value)}
              />
            </div>
            <div className={styles.twoForm}>
              <InputMask mask="99/99" value={cardExpDate} onChange={(e) => setCardExpDate(e.target.value)}>
              {(inputProps) => (
                <div className={cn(styles.formSmall, styles.inputForm)}>
                  <TextField
                    label={t("paymant.expiration")}
                    {...inputProps}
                    required
                    type="tel"
                  />
                </div>
              )}
              </InputMask>
              <div className={cn(styles.formSmall, styles.inputForm)}>
                <TextField
                  label={"CVV"}
                  required
                  type="password"
                  value={cardCVV2}
                  onChange={(e) => setCardCVV2(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.personBlock}>
              <div className={styles.greyText}>
                {t("paymant.authAmount")}
              </div>
              <div className={styles.blackText}>
                {authAmount} {authCurrency}
              </div>
            </div>
          </div>
        )
      }
      {
        <div className={styles.formCheckbox}>
          <CheckBox
            text={t("paymant.need")}
            checked={needReceipt}
            onChange={setNeedReceipt}
          />
        </div>
      }
      {
        needReceipt && (
          <div>
            <div className={cn(styles.phone, styles.inputForm)}>
              <TextField
                label={t("paymant.company")}
                value={receiptCompany}
                onChange={(e) => setReceiptCompany(e.target.value)}
              />
            </div>
            <div className={cn(styles.email, styles.inputForm)}>
              <TextField
                label={t("paymant.details")}
                value={receiptDetails}
                onChange={(e) => setReceiptDetails(e.target.value)}
              />
            </div>
          </div>
        )
      }
      <div className={styles.backButton} onClick={() => setStep(1)}>
        {`< ${t("paymant.back")}`}
      </div>
      <Button
        text={t("paymant.confirm")}
        onClick={() => {createOrder()}}
        disabled={!isNext[onlinePayment]}
      />
    </div>
  )
};

const mapStateToProps = ({ payment, userInfo }) => ({
  orderId: userInfo.orderId,
  needReceipt: userInfo.needReceipt,
  receiptFirstName: userInfo.receiptFirstName,
  receiptLastName: userInfo.receiptLastName,
  receiptCompany: userInfo.receiptCompany,
  receiptDetails: userInfo.receiptDetails,

  onlinePayment: userInfo.onlinePayment,
  cardPAN: userInfo.cardPAN,
  cardExpDate: userInfo.cardExpDate,
  cardCVV2: userInfo.cardCVV2,
  orderAmount: userInfo.orderAmount,
  orderCurrency: userInfo.orderCurrency,
  authAmount: userInfo.authAmount,
  authCurrency: userInfo.authCurrency
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAuthAmount,
  createTransfer,
  processPayment,
  setNeedReceipt,
  setReceiptCompany,
  setReceiptDetails,
  setReceiptFirstName,
  setReceiptLastName,
  setOnlinePayment,
  setCardPAN,
  setCardExpDate,
  setCardCVV2,
}, dispatch);

export const Paymant = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymantComponent);
import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styles from './passengerSelect.module.css'
import cn from 'classnames';
import { useTranslation } from "react-i18next";
import { TextField } from '../../formfields'
import {
  setPassengers,
  setChildSpace,
  setLugguageSpace
} from '../../modules/info/actions';

const PassengerSelectComponent = ({ setPassengers, setChildSpace, childSpace, passengers, lagguageSpace, setLugguageSpace }) => {
  const { t } = useTranslation();
  const [isOpen, setMenuOpen] = React.useState(false);
  const ref = React.useRef(null);

  const openMenu = React.useCallback(() => setMenuOpen(true), []);
  const closeMenu = React.useCallback(() => setMenuOpen(false), []);
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && !(window.innerWidth <= 730)) {
        closeMenu();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, isOpen]);

  const handleMenuControl = React.useCallback(() => {
      openMenu()
  }, []);

  const handleCount = (e, val, dec) => {
    if (val === 'passengers') {
      const sum = +passengers + dec;
      if (sum >= 0) {
        setPassengers(sum);
      }
    }
    if (val === 'childSpace') {
      const sum = +childSpace + dec;
      if (sum >= 0) {
        setChildSpace(sum);
      }
    }

    if (val === 'lagguage') {
      const sum = +lagguageSpace + dec;
      const maxLaguage = Math.ceil((+childSpace + passengers) / 7) * 7;
      if (sum >= 0 && sum <= maxLaguage) {
        setLugguageSpace(sum);
      }
    }
  };

  const textPassengers = passengers ? `${passengers} ${t('main.searchBlock.adults').toLowerCase()}; ` : '';
  const textChild = childSpace ? `${childSpace} ${t('main.searchBlock.children').toLowerCase()}; ` : '';
  const defaultValue = `${textPassengers}${textChild}`;

  return (
    <div className={styles.container} onClick={handleMenuControl} ref={ref}>
      <div className={cn(styles.wrapperField, styles.passengers)}>
        <TextField
          label={t('main.searchBlock.passengers')}
          required
          disabled
          onChange={null}
          value={defaultValue}
        />
      </div>
      {
        isOpen && (
          <div className={styles.menu}>
            <div className={styles.item}>
              <div className={styles.text}>
                {t('main.searchBlock.adults')}
              </div>
              <div className={styles.counterBlock}>
                <div className={styles.button} onClick={e => handleCount(e, 'passengers', -1)}>-</div>
                <div className={styles.value}>{+passengers}</div>
                <div className={styles.button} onClick={e => handleCount(e, 'passengers', 1)}>+</div>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.text}>
                {t('main.searchBlock.children')}
              </div>
              <div className={styles.counterBlock}>
                <div className={styles.button} onClick={e => handleCount(e, 'childSpace', -1)}>-</div>
                <div className={styles.value}>{+childSpace}</div>
                <div className={styles.button} onClick={e => handleCount(e, 'childSpace', 1)}>+</div>
              </div>
            </div>

            {
              +childSpace + passengers >= 5 && (
                <React.Fragment>
                  <div className={styles.divider} />

                  <div className={styles.item}>
                    <div className={styles.text}>
                      {t('main.searchBlock.lagguage')}
                    </div>
                    <div className={styles.counterBlock}>
                      <div className={styles.button} onClick={e => handleCount(e, 'lagguage', -1)}>-</div>
                      <div className={styles.value}>{+lagguageSpace}</div>
                      <div className={styles.button} onClick={e => handleCount(e, 'lagguage', 1)}>+</div>
                    </div>
                  </div>
                  <div className={cn(styles.lagguageText)}>{t('main.searchBlock.lagguageText')}</div>
                </React.Fragment>
              )
            }

          </div>
        )
      }
    </div>
  )
};

const mapStateToProps = ({ userInfo, payment }) => ({
  passengers: userInfo.passengers,
  childSpace: userInfo.childSpace,
  lagguageSpace: userInfo.lagguageSpace
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setPassengers,
  setChildSpace,
  setLugguageSpace
}, dispatch);

export const PassengerSelect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PassengerSelectComponent);
